import React from 'react'
import styled from 'styled-components';

import { Container } from '../globalStyles.css'
import FirstProject from './firstProject'
import SecondProject from './secondProject'
import ThirdProject from './thirdProject'
import FourthProject from './fourthProject'

const ProjectBodyWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 1rem;
  margin-bottom: 5rem;

  ${({theme}) => theme.media.laptop} {
    padding-bottom: 5rem;
    margin-bottom: 8rem;
  }

  ${({theme}) => theme.media.desktop} {
    margin-bottom: 10rem;
  }

  &::before {
    position: absolute;
    content: '';
    top: -1%;
    bottom: 24px;
    left: 15%;
    width: 4rem;
    background: ${({theme}) => theme.colors.mainBlue};

    ${({theme}) => theme.media.laptop} {
      top: -5%;
      bottom: 24px;
      left: 15%;
      width: 6rem;
    }

    ${({theme}) => theme.media.desktop} {
      top: -4%;
      bottom: 24px;
      left: 20%;
      width: 8rem;
    }

    ${({theme}) => theme.media.desktopXL} {
      top: -4%;
      bottom: 24px;
      left: 23%;
      width: 8rem;
    }
  }

  &::after {
    position: absolute;
    content: '';
    bottom: -2.4rem;
    left: 15%;
    width: 4rem;
    height: 4rem;
    background: ${({theme}) => theme.colors.mainBlue};
    clip-path: polygon(0 0, 50% 100%, 100% 0);

    ${({theme}) => theme.media.laptop} {
      bottom: -4.4rem;
      left: 15%;
      width: 6rem;
      height: 6rem;
    }

    ${({theme}) => theme.media.desktop} {
      bottom: -6.4rem;
      left: 20%;
      width: 8rem;
      height: 8rem;
    }

    ${({theme}) => theme.media.desktopXL} {
      bottom: -6.4rem;
      left: 23%;
      width: 8rem;
      height: 8rem;
    }
  }
`


const ProjectBody = () => {
  return (
    <ProjectBodyWrapper>
      <Container>
        <FirstProject />
        <SecondProject />
        <ThirdProject />
        <FourthProject />
      </Container>
    </ProjectBodyWrapper>
  )
}

export default ProjectBody
