import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import FirstVideo from '../../assets/video/rowery.mp4'

import { FirstProjectWrapper, VideoWrapper } from './firstProject.css'


const FirstProject = () => {
  
  const animation = useAnimation();
  const [firstProjectRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-30%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <FirstProjectWrapper 
      ref={firstProjectRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
        opacity: 0,
        y: '50px',
        }
      }}
    >
      <VideoWrapper>
        <video autoPlay loop muted>
          <source src={FirstVideo} type="video/mp4"></source>
        </video>
        <h2>Triathlon Polska Bydgoszcz Borówno</h2>
      </VideoWrapper>
      
      <p>Tego dnia Bydgoszcz stała się triathlonową stolicą Polski. W zawodach wzięło udział ponad tysiąc zawodników, którzy rywalizowali na kilku dystansach.</p>
    </FirstProjectWrapper>
  )
}

export default FirstProject
