import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'

import { ProjectBanerWrapper, BannerTitle, Headline, ImageWrapper } from './projectBaner.css'

// import ProjectImg from '../../images/projectImage.svg';


const ProjectBaner = () => {

  // const imgProjectsWrapper = useRef(null);

  // useEffect(() => {

  //   const div = imgProjectsWrapper.current;
  //   const [elements] = imgProjectsWrapper.current.children;

  //   const playIco = elements.getElementById('Play')
  //   const controls = elements.getElementById('Controls')
  //   const screen = elements.getElementById('Screen')

  //   gsap.set(screen, {transformOrigin: '50%, 50%', scale: 0, autoAlpha: 0});
  //   gsap.set(controls, {autoAlpha: 0, y: '100'});
  //   gsap.set(playIco, {transformOrigin: '50%, 50%', scale: 1.4, autoAlpha: 0});

  //   const tlProjects = gsap.timeline();

  //   tlProjects.to(div, {opacity: 1}, 0.3)
  //     .to(screen, {duration: .7, scale: 1, autoAlpha: 1})
  //     .to(playIco, {duration: 1, scale: 1, autoAlpha: 1})
  //     .to(controls, {duration: .7, autoAlpha: 1, y: 0}, '-=0.5')
  //     .fromTo(playIco, {scale: 1}, {duration: 1.5, scale: 1.1, repeat: -1, yoyo: true,})
  
  // }, []);

  const parentProject = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        staggerChildren: .2,
      },
    },
  }

  const childProject = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.9],
      },
    },
  }

  return (
    <ProjectBanerWrapper>
      <BannerTitle variants={parentProject} initial='initial' animate='animate'>
        <Headline variants={childProject}>Nasze</Headline>
        <Headline variants={childProject}>Projekty</Headline>
      </BannerTitle>

      {/* <ImageWrapper ref={imgProjectsWrapper}>
        <ProjectImg/>
      </ImageWrapper> */}
    </ProjectBanerWrapper>
  )
}

export default ProjectBaner
