import React from 'react'

import SEO from "../components/seo"
import Layout from "../components/layout"

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'

import ProjectBaner from '../components/ProjectsPage/projectBaner'
import ProjectBody from '../components/ProjectsPage/projectBody'

const ProjectPage = (props) => {

  const { cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  return (
    <Layout>
      <SEO title="Projekty - BluStreamTV" />

      <ProjectBaner />
      <ProjectBody onCursor={onCursor}/>
      
    </Layout>
  )
}

export default ProjectPage
