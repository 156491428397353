import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import SecondVideo from '../../assets/video/mecz.mp4'

import { SecondProjectWrapper, VideoWrapper } from './secondProject.css'


const SecondProject = () => {

  
  const animation = useAnimation();
  const [secondProjectRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-30%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <SecondProjectWrapper 
      ref={secondProjectRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
        opacity: 0,
        y: '50px',
        }
      }}
    >
      <VideoWrapper>
        <video autoPlay loop muted>
          <source src={SecondVideo} type="video/mp4"></source>
        </video>

        <h2>Unia Drobex Solec Kujawski vs Zawisza Bydgoszcz</h2>
      </VideoWrapper>
      
      <p>Jeden z wielu meczy Unii Drobex Solec Kujawski transmitowany przez kanał BluStreamTV.</p>
    </SecondProjectWrapper>
  )
}

export default SecondProject
