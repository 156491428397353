import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import FourthVideo from '../../assets/video/koncert.mp4'
import ExImg from '../../images/ex-image-new.svg'
import CircleImg from '../../images/circle-image-new.svg'

import { FourthProjectWrapper, VideoWrapper, ExWrapper, ExWrapperCircle } from './fourthProject.css'


const FourthProject = () => {

  const animation = useAnimation();
  const [fourthProjectRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-30%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <FourthProjectWrapper 
      ref={fourthProjectRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
        opacity: 0,
        y: '50px',
        }
      }}
    >
      <VideoWrapper>
        <video autoPlay loop muted>
          <source src={FourthVideo} type="video/mp4"></source>
        </video>

        <h2>Koncert zespołu ROAN z Solca Kujawskiego</h2>
      </VideoWrapper>
      
      <p>Podczas pandemi koronawirusa i obostrzeń z tym związanych zespół ROAN postanowił strimingować koncert z domu dla swoich fanów.</p>
      <ExWrapper>
        <ExImg />
      </ExWrapper>
      <ExWrapperCircle>
        <CircleImg />
      </ExWrapperCircle>
    </FourthProjectWrapper>
  )
}

export default FourthProject
