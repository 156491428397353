import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const animationCircle = keyframes`
  0% { transform: translate(20px, 20px); opacity: 0.1 }
  30% { transform: translate(0, 0); opacity: 1  }
  60% { transform: translate(0, -30px); opacity: 1  }
  100% { transform: translate(20px, 20px); opacity: 0.1 }
`

const animationEx = keyframes`
  0% { transform: scale(1) }
  50% { transform: scale(1.1) }
  100% { transform: scale(1) }
`


export const FourthProjectWrapper = styled(motion.section)`
  position: relative;
  padding: 3rem 0 4rem;

  p {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 2.5rem;

    ${({theme}) => theme.media.laptop} {
      font-size: 1.4rem;
      margin-top: 3rem;
      width: 80%;
    }

    ${({theme}) => theme.media.laptopXL} {
      margin-top: 2.5rem;
      margin-left: 10rem;
      font-size: 1.6rem;
      width: 65%;
    }

    ${({theme}) => theme.media.desktop} {
      margin-top: 3rem;
      margin-left: 12rem;
      width: 60%;
    }

    ${({theme}) => theme.media.desktopXL} {
      margin-bottom: 3rem;
    }
  }
`


export const VideoWrapper = styled.div`
  position: relative;

  video {
    width: 100%;
    filter: drop-shadow(0px 0px 8px rgba(20,20,20, .4));

    ${({theme}) => theme.media.laptop} {
      width: 75%;
    }

    ${({theme}) => theme.media.desktopXL} {
      width: 85%;
    }
  }

  h2 {
    position: absolute;
    bottom: -1.5rem;
    left: 1.5rem;
    font-size: 1.9rem;
    font-weight: 900;
    line-height: 1;
    margin: 0;

    ${({theme}) => theme.media.laptop} {
      bottom: -2rem;
      left: 3rem;
      font-size: 3.5rem;
      line-height: 3rem;
      margin: 0;
    }

    ${({theme}) => theme.media.laptopXL} {
      left: 3rem;
      bottom: -2rem;
      font-size: 4rem;
      line-height: 3.5rem;
    }

    ${({theme}) => theme.media.desktop} {
      left: 2rem;
      bottom: -1.5rem;
    }

    ${({theme}) => theme.media.desktopXL} {
      left: 4rem;
      bottom: -1.5rem;
    }
  }
`


export const ExWrapper = styled.div`
  width: 10rem;
  position: absolute;
  bottom: -15%;
  right: 0;
  z-index: -1;
  animation: ${animationEx} 8s linear infinite;
  transform-origin: center;

  .cls-1 {
    stroke: #FF0000;
  }

  ${({theme}) => theme.media.laptop} {
    width: 15rem;
  }
`

export const ExWrapperCircle = styled.div`
  width: 6rem;
  position: absolute;
  bottom: -20%;
  right: -10%;
  z-index: -1;
  animation: ${animationCircle} 8s linear infinite;
  transform-origin: center;

  .cls-1 {
    stroke: ${({theme}) => theme.color};
  }

  ${({theme}) => theme.media.laptop} {
    width: 10rem;
  }
`