import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import ThirdVideo from '../../assets/video/szubiniacy.mp4'

import { ThirdProjectWrapper, VideoWrapper } from './thirdProject.css'


const ThirdProject = () => {

  
  const animation = useAnimation();
  const [thirdProjectRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-30%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <ThirdProjectWrapper 
      ref={thirdProjectRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
        opacity: 0,
        y: '50px',
        }
      }}
    >
      <VideoWrapper>
        <video autoPlay loop muted>
          <source src={ThirdVideo} type="video/mp4"></source>
        </video>

        <h2>Dyskoteka na balkonach szubiniacy</h2>
      </VideoWrapper>
      
      <p>Transmisja z dyskoteki zorganizowanej przez Stowarzyszenie Mieszkańców Gminy Szubin. #szubiniacy zostają w domach i bawią się na balkonach.</p>
    </ThirdProjectWrapper>
  )
}

export default ThirdProject
